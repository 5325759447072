<template>
  <div class="users">
    <div class="row" style="margin: 20px 0">
        <div class="col-6">
          <dl>
                <dt>ID:</dt>
                <dd>{{ customer.id }}</dd>

                <dt>Fullname:</dt>
                <dd>{{ customer.fullname }}</dd>

                <dt>Email:</dt>
                <dd>{{ customer.email }}</dd>

                <dt>Account Enabled:</dt>
                <dd>{{ customer.enabled }}</dd>

                <dt>Delinquent:</dt>
                <dd>{{ customer.delinquent }}</dd>

                <dt>Password Reset Mode:</dt>
                <dd>{{ customer.reset }}</dd>

                <dt>Country:</dt>
                <dd>{{ customer.country }}</dd>

                <dt>Last Login Platform:</dt>
                <dd>{{ customer.last_login_platform }}</dd>

                <dt>Last Login Time:</dt>
                <dd>{{ customer.last_login_time | moment("dddd, MMMM Do YYYY") }}</dd>

                <dt>Created On:</dt>
                <dd>{{ customer.created_on | moment("dddd, MMMM Do YYYY")}}</dd>
          </dl>
        </div>
        <div class="col-6">
          <dl>
            <dt>Found out From</dt>
            <dd>{{ customer.found_out_from }}</dd>

            <dt>Stripe Customer ID:</dt>
            <dd>{{ customer.stripe_customer_id }}</dd>

            <dt>Stripe Subscription ID:</dt>
            <dd>{{ customer.stripe_subscription }}</dd>

            <dt>Subscribed On:</dt>
            <dd>{{ customer.subscribed_on | moment("dddd, MMMM Do YYYY") }}</dd>

            <dt>Cancelled On:</dt>
            <dd>{{ customer.cancelled_on | moment("dddd, MMMM Do YYYY") }}</dd>

            <dt>Plan:</dt>
            <dd>{{ customer.plan }}</dd>

            <dt>Found out From:</dt>
            <dd>{{ customer.found_out_from }}</dd>
          </dl>
        </div>
      </div>
    <form class="form-horizontal" v-on:submit.prevent="saveChanges">
      <div class="form-group">
        <label for="email">Email:</label>
        <input
          type="text"
          name="email"
          v-model="customer.email"
          placeholder="Email"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="title">Fullname:</label>
        <input
          type="text"
          name="fullname"
          v-model="customer.fullname"
          placeholder="Fullname"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label for="country">Country:</label>
        <select class="form-control" name="country" v-model="customer.country">
          <option
            v-for="country in countries"
            v-bind:value="country.value"
            v-bind:key="country.value"
          >
            {{ country.title }}
          </option>
        </select>
      </div>

      <div class="form-group signup-carousel-buttons">
        <button class="btn btn-primary">Save</button>
      </div>
    </form>
  </div>
</template>

<style scoped>

dt:before {
  content: "";
  display: block;
}
dt {
  margin-right: 5px;
}
dt, dd {
  display: inline;
  line-height: 1.8em
}
</style>

<script>
import countries from "../../data/countries.json";
import UserService from "../../services/UserService";

export default {
  data: function () {
    return {
      customer: {},
      countries: countries,
    };
  },

  created: function () {
    if (!isNaN(this.$route.params.id)) {
      UserService.findById(this.$route.params.id).then((response) => {
        this.customer = response.data;
      });
    } else {
      alert("Invalid parameter.");
    }
  },

  methods: {
    saveChanges: function () {
      let customerRow = {
        id: this.customer.id,
        email: this.customer.email,
        country: this.customer.country,
        fullname: this.customer.fullname,
      };

      UserService.update(this.$route.params.id, customerRow).then((response) => {
        alert("User Updated");
      });
    },
  },
};
</script>
